import { createSlice } from "@reduxjs/toolkit";
import { userManagementHash } from '../../Pages/Private/UserManagement/components/data';

const buyerDetailsSlice = createSlice({
    name: "buyerDetails",
    initialState: null,
    reducers: {
        setBuyerDetails: (state, action) => {
            state = action.payload
            return state
        }
    },
})


const { actions, reducer: buyerDetailsReducer } = buyerDetailsSlice

const { setBuyerDetails } = actions

export {
    setBuyerDetails,
    buyerDetailsReducer 
}