import { request } from "../Utils";

const BASE_URL = "auth";

export const loginAdmin = (data) =>
  request({
    url: `${BASE_URL}/email/login`,
    method: "POST",
    data,
  });

export const updatePassword = (data) =>
  request({
    url: `${BASE_URL}/update-password`,
    method: "POST",
    data,
  });
