import {
  DashboardIcon,
  InventoryIcon,
  LogisticsIcon,
  OrderIcon,
  SettingsIcon,
  UserIcon,
} from "../../Assets/Svgs";

export const sideBarMenus = [
  {
    id: 0,
    path: "/dashboard",
    title: "Dashboard",
    icon: DashboardIcon,
  },
  {
    id: 1,
    path: "/inventory",
    title: "Inventory",
    icon: InventoryIcon,
  },
  {
    id: 2,
    path: "/user-management",
    title: "User Management",
    icon: UserIcon,
  },
  {
    id: 3,
    path: "/order-management",
    title: "Order Management",
    icon: OrderIcon,
  },
  {
    id: 4,
    path: "/logistics",
    title: "Logistics System",
    icon: LogisticsIcon,
  },
  {
    id: 5,
    path: "/setting",
    title: "Setting",
    icon: SettingsIcon,
  },
];
