import { request } from "../Utils";


export const getProducts = (filter) =>
    request({
        url: `/products`,
        params: {
            ...filter
        }
    });


export const getSellerProducts = (id) =>
    request({
        url: `sellers/${id}/products`,
    });

export const getProductDetails = (id) =>
    request({
        url: `products/${id}`,
        method: "GET",
    });

export const createProducts = (data, id) => {
    return request({
        url: `store/${id}/product`,
        method: "POST",
        data,
    });
};

export const productAnalytics = () => {
    return request({
        url: `admin/product/counts`,
        method: "GET",
    });
};


export const deleteProduct = (id, productId) =>
    request({
        url: `store/${id}/product/${productId}`,
        method: "DELETE",
    });

export const editProduct = (data, id, productId) => {
    return request({
        url: `store/${id}/product/${productId}`,
        method: "PUT",
        data,
    });
};

export const toggleProductStatus = (data) => {
    return request({
        url: `admin/product/update`,
        method: "PUT",
        data,
    });
};


export const replaceProductImage = (data, storeId, productId) => {
    return request({
        url: `store/${storeId}/product/${productId}/images/replace`,
        method: "POST",
        data,
    });
};


export const deleteProductDiscount = (data, storeId, productId) => {
    return request({
        url: `store/${storeId}/product/${productId}/${storeId}/product/${productId}/discount`,
        method: "DELETE",
        data,
    });
};

export const createProductDiscount = (data, storeId, productId) => {
    return request({
        url: `store/${storeId}/product/${productId}/${storeId}/product/${productId}/discount`,
        method: "POST",
        data,
    });
};