import { createSlice } from "@reduxjs/toolkit";

const GlobalSlice = createSlice({
  name: "global",
  initialState: {},
  reducers: {
    setConversionRate: (state, action) => {
      state.conversionRate = action.payload;
      return state;
    },
  },
});

const { actions, reducer: GlobalReducer } = GlobalSlice;

const {
  setConversionRate,
} = actions;

export {
  GlobalReducer,
  setConversionRate,
};
