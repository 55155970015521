import styled from "styled-components";
import { EmptyCubeIcon } from "../../../../Assets/Svgs";

export const EmptyUser = () => {
  return (
    <Container>
      <Content>
        <IconHolder>
          <EmptyCubeIcon />
          <p>No user listed yet</p>
        </IconHolder>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const IconHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & > svg {
    width: 50px;
    height: 50px;
  }
`;
