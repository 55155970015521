import styled from "styled-components";
import { useMemo, useState } from "react";
import { AdminTable } from "../../../Components";
import {
  devices,
  formatAmount,
  formatImage,
  truncateText,
  wholesaleResponse,
} from "../../../../../Utils";
import { Search, RightArrow } from "../../../../../Assets/Svgs";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { EmptyUser } from "../../components/empty";
import { LineLoader, PopMenu } from "../../../../../Ui_elements";
import { ThreeDotMenuIcon } from "../../../../../Assets/Svgs";
import {
  getWholesaleBuyers,
  respondToWholesaleBuyers,
} from "../../../../../Urls";
import { useApiGet, useApiSend } from "../../../../../Hooks";
import toast from "react-hot-toast";
import { RejectItemModdal } from "../../components/rejectItemModal";
import { ApproveModal } from "../../components/approveModal";
import { FlagModal } from "../../components/flagModal";
import { QueryModal } from "../../components/queryModal";
import { PendingModal } from "../../components/pendingModal";

export const WholesaleRequestTable = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const storeId = user?.storeId;
  const { hash } = useLocation();
  const [searchFocus, setSearchFocus] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [openModal, setOpenModal] = useState({
    rejectModal: false,
    flagModal: false,
    queryModal: false,
    pendingModal: false,
    approvedModal: false,
  });

  const { data, isLoading, isFetching } = useApiGet(
    ["get-wholesale-buyers"],
    () => getWholesaleBuyers(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isPending } = useApiSend(
    respondToWholesaleBuyers,
    () => {
      toast.success("Wholesale request update successfully");
    },
    () => {
      toast.error("Wholesale request couldn't be updated");
    },
    ["get-wholesale-buyers"]
  );

  const handleDetailsClick = (order) => {
    navigate({ hash: hash, pathname: `order_details/${order?.id}` });
  };

  const menuItems = (row) => [
    // {
    //   item: "View Details",
    //   action: () => { },
    // },
    {
      item: <Yes>Approve Request</Yes>,
      action: () =>
        setOpenModal({
          ...openModal,
          approvedModal: true,
        }),
    },
    // {
    //   item: "Set Request To Pending",
    //   action: () =>
    //     setOpenModal({
    //       ...openModal,
    //       pendingModal: true,
    //     }),
    // },
    // {
    //   item: "Query Request",
    //   action: () =>
    //     setOpenModal({
    //       ...openModal,
    //       queryModal: true,
    //     }),
    // },
    // {
    //   item: <Delete>Flag Request</Delete>,
    //   action: () =>
    //     setOpenModal({
    //       ...openModal,
    //       flagModal: true,
    //     }),
    // },
    {
      item: <Delete>Reject Request</Delete>,
      action: () =>
        setOpenModal({
          ...openModal,
          rejectModal: true,
        }),
    },
  ];

  const columns = useMemo(
    () => [

      {
        Header: "Buyer Name",
        accessor: "",
        Cell: ({ row }) => (
          <p>{truncateText(row?.user?.firstName, 30) + " " + truncateText(row?.user?.lastName, 30)}</p>
        ),
        // width: "20%",
      },
      {
        Header: "Buyer Email",
        accessor: "",
        Cell: ({ row }) => (
          <p>{row?.user?.email}</p>
        ),
        // width: "20%",
      },
      {
        Header: "Business Name",
        accessor: "business[businessName]",
        Cell: ({ row }) => (
          <p>{truncateText(row?.business?.businessName, 30)}</p>
        ),
        // width: "20%",
      },
      {
        Header: "Business CAC No.",
        accessor: "businessCACNo",
        Cell: ({ row }) => (
          <p>{truncateText(row?.business?.businessCACNo, 20)}</p>
        ),
      },
      {
        Header: "Business Type",
        accessor: "businessType",
        Cell: ({ row }) => (
          <p>{truncateText(row?.business?.businessType, 20)}</p>
        ),
      },
      {
        Header: "Wholesale Access",
        accessor: "wholeSaleAccess",
        Cell: ({ row }) =>
          row?.wholeSaleAccess ? <Yes>Yes</Yes> : <No>No</No>,
      },
      {
        Header: "Status",
        accessor: "wholeSaleStatus",
        // Cell: ({ row }) => (
        //   <p>{truncateText(row?.business?.businessType, 20)}</p>
        // ),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => (
          <PopMenu
            onClick={() => {
              setRequestId(row.id);
            }}
            menuItems={menuItems(row)}
          >
            <Menu>
              <ThreeDotMenuIcon />
            </Menu>
          </PopMenu>
        ),
        width: "10px",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Container>
      <RejectItemModdal
        deleteAction={() =>
          mutate({
            requestId: requestId,
            status: wholesaleResponse.rejected,
          })
        }
        isLoading={isPending}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <ApproveModal
        deleteAction={() =>
          mutate({
            requestId: requestId,
            status: wholesaleResponse.approved,
          })
        }
        isLoading={isPending}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <QueryModal
        deleteAction={() =>
          mutate({
            requestId: requestId,
            status: wholesaleResponse.queried,
          })
        }
        isLoading={isPending}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <FlagModal
        deleteAction={() =>
          mutate({
            requestId: requestId,
            status: wholesaleResponse.flagged,
          })
        }
        isLoading={isPending}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <PendingModal
        deleteAction={() =>
          mutate({
            requestId: requestId,
            status: wholesaleResponse.pending,
          })
        }
        isLoading={isPending}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      {data?.length > 0 ? (
        <>
          <AdminTable data={data} columns={columns} />
        </>
      ) : (
        <EmptyUser />
      )}
      <LineLoader loading={isLoading || isFetching || isPending} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60vh;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    flex-shrink: 0;
    background-color: var(--hover-color);
  }

  @media ${devices.laptop} {
    align-items: center;
    gap: 12px;

    img {
      width: 30px;
      height: 30px;
    }

    p {
      font-size: 14px;
    }
  }
`;

const Order = styled.p`
  font-size: 15px;
  color: #ff4623;
  font-weight: 500;
`;

const Label = styled.p`
  font-size: 15px;
`;

const DetailsArrow = styled(RightArrow)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    path {
      fill: #bf2b0f;
    }
  }

  path {
    transition: all 0.25s ease;
    fill: #ff4623;
  }
`;

const ItemName = styled(Link)`
  font-size: 15px;
  cursor: pointer;
  width: 70%;
  &:hover {
    text-decoration: underline;
  }
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;

  h6 {
    font-size: 20px;
  }
`;

const SearchFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SearchContainer = styled.div`
  display: flex;
  width: 200px;
  gap: 10px;
  border: ${({ $searchFocus }) => ($searchFocus ? "1px solid black" : "none")};
  padding: ${({ $searchFocus }) => ($searchFocus ? "5px" : "none")};
  border-radius: ${({ $searchFocus }) => ($searchFocus ? "10px" : "none")};
  outline: none;
  input {
    font-size: 16px;
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
  }

  transition: all 0.3s ease;

  @media ${devices.laptop} {
    input {
      font-size: 0.8rem;
    }
  }
`;

const Menu = styled.div`
  cursor: pointer;
`;

const Yes = styled.p`
  color: green !important;
`;

const No = styled.p`
  color: var(--primary-color) !important;
`;

const Delete = styled.p`
  color: var(--primary-color);
`;
