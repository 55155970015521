import * as yup from "yup";

export const PasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required()
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{6,})/,
      "Password must contain at least one number, one lowercase letter, one special character, and be at least 6 characters long"
    ),
  newPassword: yup
    .string()
    .required()
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{6,})/,
      "Password must contain at least one number, one lowercase letter, one special character, and be at least 6 characters long"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Your passwords do not match"),
});

export const PersonalSchema = yup.object().shape({
  firstName: yup.string().required("please input your first name"),
  lastName: yup.string().required("please input your last name"),
  phoneNumber: yup.string().required("please input your number"),
  country: yup.mixed().required("please select a country"),
});
