import * as React from "react";
import { styled } from "@mui/material/styles";
import * as styledComp from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { devices } from "../../../Utils";

const StyledTableCell = styled(TableCell)(({ width, cartTotal, column }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: "var(--black)",
    // borderRight: "0.5px solid var(--gray-200)",
    borderBottom: "none",
    fontSize: "1rem",
    textAlign: cartTotal ? "start" : "start",
    width: column?.width || "fit-content",
    maxWidth: width,
    "@media (max-width: 768px)": {
      fontSize: "0.8rem",
      padding: "0.5rem",
    },
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: "1rem",
    backgroundColor: "transparent",
    padding: "1rem",
    whiteSpace: "normal",
    wordWrap: "break-word",
    textAlign: "start",
    width: column?.width || "fit-content",
    "@media (max-width: 768px)": {
      fontSize: "0.8rem",
      padding: "0.5rem",
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "transparent",
  },
  ":hover": {
    backgroundColor: "var(--gray-50)",
  },
  // hide last border
  "& td, & th": {
    // borderRight: "0.2px solid var(--gray-200)",
    borderBottom: "1px solid var(--gray-200)",
    // borderLeft: "1px solid var(--gray-200)",
  },
}));

const TableContainer = styled("div")(({ tableWidth }) => ({
  overflowX: "auto",
  overflowY: "auto",
  width: tableWidth || "100%",
  height: "auto",
  borderBottom: "1px solid var(--gray-200)",
  // "& table": {
  //   minWidth: "100%",
  //   tableLayout: "fixed",
  // },
  // "@media (max-width:768px)": {
  //   whiteSpace: "nowrap",
  //   overflowX:"auto"
  // }
}));

export const AdminTable = ({
  columns,
  data,
  tableWidth,
  cartTotal,
  cellWidth,
  pagination,
  onRowClick,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container tableWidth={tableWidth}>
      <Table
        sx={{ minWidth: tableWidth || 700 }}
        stickyHeader
        aria-label="customized table"
      >
        <TableHead>
          <StyledTableRow>
            {columns?.map((column, index) => (
              <StyledTableCell
                key={index}
                width={cellWidth}
                cartTotal={cartTotal}
                column={column}
              >
                {typeof column.Header === "string" ? (
                  <span>{column.Header}</span>
                ) : (
                  column.Header()
                )}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, rowIndex) => (
              <StyledTableRow
                key={rowIndex}
                onClick={() => onRowClick && onRowClick(row)}
              >
                {columns.map((column, colIndex) => (
                  <StyledTableCell key={colIndex}>
                    {column.Cell ? (
                      <column.Cell row={row} />
                    ) : (
                      row[column.accessor]
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>

      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Container>
  );
};

const Container = styledComp.default(TableContainer)`
    @media ${devices.laptop} {
        .MuiTableCell-root.MuiTableCell-body {
            font-size: 16px;
            padding: 0.5rem;
        }
        
        .MuiTableCell-root.MuiTableCell-head {
            font-size: 16px;
            padding: 14px;
        }

        .MuiButtonBase-root-MuiMenuItem-root {
            p {
                font-size: 16px;
            }
        }
    }
`;
