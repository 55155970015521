import { lazy } from "react";
import { AuthLayout } from "../Layouts";

const Login = lazy(() => import("../Pages/Shared/Login"));

const LandingPage = lazy(() => import("../Pages/Private"));
const Dashboard = lazy(() => import("../Pages/Private/Dashboard"));
const Profile = lazy(() => import("../Pages/Private/Profile"));
const Inventory = lazy(() => import("../Pages/Private/Inventory"));
const UserManagement = lazy(() => import("../Pages/Private/UserManagement"));
const BuyerDetails = lazy(() =>
  import("../Pages/Private/UserManagement/sections/buyers/details")
);
const SellerDetails = lazy(() =>
  import("../Pages/Private/UserManagement/sections/sellers/details")
);
const OrderManagement = lazy(() => import("../Pages/Private/Orders"));
const LogisticSystem = lazy(() => import("../Pages/Private/LogisticSystem"));
const CreateCategory = lazy(() =>
  import("../Pages/Private/Inventory/createCategory")
);
const ErrorPage = lazy(() => import("../Ui_elements/ErrorPage"));
const ViewOrderItem = lazy(() => import("../Pages/Private/Orders/details"));
const ViewBuyerOrder = lazy(() =>
  import("../Pages/Private/UserManagement/sections/buyers/ordersPages")
);
const ViewSellerOrder = lazy(() =>
  import("../Pages/Private/UserManagement/sections/sellers/ordersPages")
);
const Setting = lazy(() => import("../Pages/Private/Setting"));
const ViewProducts = lazy(() => import("../Pages/Private/Inventory/viewProductDetails"));



export const routes = [
  {
    path: "/",
    element: LandingPage,
  },
  {
    path: "/dashboard",
    element: Dashboard,
  },
  {
    path: "/inventory",
    element: Inventory,
  },
  {
    path: "/user-management",
    element: UserManagement,
  },
  {
    path: "/user-management/buyer-details/:id",
    element: BuyerDetails,
  },
  {
    path: "/user-management/seller-details/:id",
    element: SellerDetails,
  },
  {
    path: "/order-management",
    element: OrderManagement,
  },
  {
    path: "/order-management/order-details/:id",
    element: ViewOrderItem,
  },
  {
    path: "/profile",
    element: Profile,
  },
  {
    path: "/user-management/:id/orders",
    element: ViewBuyerOrder,
  },
  {
    path: "/user-management/seller/:id/orders",
    element: ViewSellerOrder,
  },
  {
    path: "/logistics",
    element: LogisticSystem,
  },
  {
    path: "/setting",
    element: Setting,
  },

  {
    path: "/settings/create_category",
    element: CreateCategory,
  },

  {
    path: "/inventory/view/:id",
    element: ViewProducts,
  },

  {
    path: "/*",
    element: () => <ErrorPage />,
    hasLayout: true,
  },
];

export const authRoutes = [
  {
    path: "/",
    element: () => (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
    hasLayout: true,
  },
  {
    path: "/login",
    element: () => (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
    hasLayout: true,
  },
  {
    path: "/*",
    element: () => <ErrorPage />,
    hasLayout: true,
  },
];
