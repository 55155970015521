import { request } from "../Utils";

const BASE_URL = "admin";

export const getOrders = (filter) =>
  request({
    url: `${BASE_URL}/orders`,
    method: "GET",
    params: {
      ...filter,
    },
  });

export const getSingleOrder = (id) =>
  request({
    url: `${BASE_URL}/orders/${id}`,
    method: "GET",
  });

export const markAsCompleted = (orderId) => {
  request({
    url: `${BASE_URL}/orders/${orderId}/completeOrder`,
    method: "POST",
  });
};
