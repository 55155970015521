import { Link } from "react-router-dom";
import styled from "styled-components";
import { devices } from "../../../../../Utils";

export const MenuItem = ({ item: { path, icon: Icon, title }, active }) => {
  return (
    <Container $active={active} to={path}>
      <Icon />
      {title}
    </Container>
  );
};

const Container = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ $active }) => ($active ? `#FF4623` : `#FBE9EB`)};
  font-size: ${({ $active }) => ($active ? `22px` : `20px`)};
  font-style: normal;
  font-weight: ${({ $active }) => ($active ? `500` : `400`)};
  line-height: 120%;
  transition: all 0.25s ease;

  & > svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;

    & path {
      transition: all 0.25s ease;
      fill: ${({ $active }) => ($active ? `#FF4623` : `#FBE9EB`)};
    }
  }

  &:hover {
    & > svg path {
      fill: #ff4623;
    }
  }

  @media ${devices.laptop} {
    font-size: ${({ $active }) => ($active ? `18px` : `16px`)};
  }
`;
