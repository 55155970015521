import styled from "styled-components";
import { useMemo, useState } from "react";
import { AdminTable } from "../../../Components";
import {
  devices,
  formatAmount,
  formatImage,
  truncateText,
} from "../../../../../Utils";
import { Search, RightArrow } from "../../../../../Assets/Svgs";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { EmptyUser } from "../../components/empty";
import { LineLoader, PopMenu } from "../../../../../Ui_elements";
import { ThreeDotMenuIcon } from "../../../../../Assets/Svgs";
import { useApiGet, useApiSend } from "../../../../../Hooks";
import { approveStores, getStores } from "../../../../../Urls";
import { ApproveSellerModal } from "./components/approveModal";
import { DeactivateSellerModdal } from "./components/deactivateModal";
import toast from "react-hot-toast";
import { setStoreId } from "../../../../../Redux/Reducers";
import { Pagination } from "@mui/material";
import { ProductPageLoading } from "../../components/loadingState";


export const SellersTable = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const storeId = user?.storeId;
  const [searchFocus, setSearchFocus] = useState(false);
  const [sellerId, setSellerId] = useState('')
  const [openModal, setOpenModal] = useState({
    deactivateModal: false,
    approveModal: false
  })
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10
  })

  const { data, isLoading, isFetching } = useApiGet(
    [`get-buyers${pagination.pageNumber}${pagination.pageSize}`],
    () => getStores(pagination),
    {
      enabled: !!pagination.pageNumber,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isPending } = useApiSend(
    () => approveStores(sellerId),
    () => {
      toast.success("Seller Status Updated")
    },
    (e) => {
      toast.error(`Something went wrong: ${e.message}`)
    }
  )


  const menuItems = (row) => [
    {
      item: "View details",
      action: () => {
        navigate(`/user-management/seller-details/${row?._id}`, {
          state: {
            id: row?._id,
            sellerName: row?.sellerName,
            isActive: row?.isActive,
            isVerified: row?.isVerified,
            products: row?.products
          }
        })
      },
    },
    {
      item: <Yes>Verify KYC</Yes>,
      action: () => setOpenModal({
        ...openModal,
        approveModal: true
      }),
    },
    {
      item: <No>Deactivate Seller</No>,
      action: () => setOpenModal({
        ...openModal,
        deactivateModal: true
      }),
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Seller Name",
        accessor: "sellerName",
        Cell: ({ row }) => (
          <Name onClick={() => {
            navigate(`/user-management/seller-details/${row?._id}`, {
              state: {
                id: row?._id,
                sellerName: row?.sellerName,
                isActive: row?.isActive,
                isVerified: row?.isVerified,
                products: row?.products
              }
            })
          }}>{row?.sellerName}</Name>
        ),
        width: "32%",
      },
      {
        Header: "Store Name",
        accessor: "storeName",
      },
      {
        Header: "No of Products",
        accessor: "products",
      },
      {
        Header: "Active",
        accessor: "count",
        Cell: ({ row }) => {
          return row?.isActive ? <Yes>Active</Yes> : <No>Deactivated</No>;
        },
      },
      {
        Header: "Kyc Verified",
        accessor: "count",
        Cell: ({ row }) => {
          return row?.isVerified ? <Yes>Verified</Yes> : <No>Not Verified</No>;
        },
      },
      // {
      //   Header: "Action",
      //   accessor: "",
      //   Cell: ({ row }) => (
      //     <PopMenu
      //       onClick={() => {
      //         setSellerId(row?._id)
      //       }}
      //       menuItems={menuItems(row)}
      //     >
      //       <Menu>
      //         <ThreeDotMenuIcon />
      //       </Menu>
      //     </PopMenu>
      //   ),
      //   width: "10px",
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePagination = (e, value) => {
    setPagination({
      ...pagination,
      pageNumber: value
    })
  }



  return (
    <Container>
      <ApproveSellerModal
        action={() => mutate()}
        isLoading={isPending}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <DeactivateSellerModdal
        action={() => { }}
        isLoading={isPending}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      {data?.length > 0 ? (
        <>
          <FilterContainer>
            <SearchFilterContainer>
              <SearchContainer $searchFocus={searchFocus}>
                <Search />
                <input
                  onFocus={() => setSearchFocus(true)}
                  onBlur={() => setSearchFocus(false)}
                  placeholder="Search by product name"
                />
              </SearchContainer>

              {/* <PopMenu menuItems={menuItems}>
            <FilterBox>
            <p>{filter}</p>
              <DownArrow />
              </FilterBox>
            </PopMenu> */}
            </SearchFilterContainer>
          </FilterContainer>
          <AdminTable data={data} columns={columns} />

        </>
      ) : (
        <EmptyUser />
      )}
      <PaginationContainer>
        <Pagination
          count={6}
          shape="rounded"
          onChange={handlePagination}
          page={pagination.pageNumber}
        />
      </PaginationContainer>
      <LineLoader loading={isLoading || isFetching || isPending} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60vh;
`;

const PaginationContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    flex-shrink: 0;
    background-color: var(--hover-color);
  }

  @media ${devices.laptop} {
    align-items: center;
    gap: 12px;

    img {
      width: 30px;
      height: 30px;
    }

    p {
      font-size: 14px;
    }
  }
`;


const Name = styled.a`
  font-size: 1rem;
  font-weight: 500;
  &:hover{
    text-decoration: underline;
    cursor:pointer;
    color: var(--primary-color);
  }
`

const Yes = styled.p`
  color: green !important;
  font-size: 1rem;
`

const No = styled.p`
  color: var(--primary-color) !important;
  font-size: 1rem;
`

const Order = styled.p`
  font-size: 15px;
  color: #ff4623;
  font-weight: 500;
`;

const Label = styled.p`
  font-size: 15px;
`;

const DetailsArrow = styled(RightArrow)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    path {
      fill: #bf2b0f;
    }
  }

  path {
    transition: all 0.25s ease;
    fill: #ff4623;
  }
`;

const ItemName = styled(Link)`
  font-size: 15px;
  cursor: pointer;
  width: 70%;
  &:hover {
    text-decoration: underline;
  }
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;

  h6 {
    font-size: 20px;
  }
`;

const SearchFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
justify-content: flex-end;
`;

const SearchContainer = styled.div`
  display: flex;
  width: 300px;
  gap: 10px;
  border: ${({ $searchFocus }) => ($searchFocus ? "1px solid black" : "none")};
  padding: ${({ $searchFocus }) => ($searchFocus ? "5px" : "none")};
  border-radius: ${({ $searchFocus }) => ($searchFocus ? "10px" : "none")};
  outline: none;
  background-color: var(--gray-200);
  padding: 10px;

  input {
    font-size: 16px;
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
  }

  transition: all 0.3s ease;

  @media ${devices.laptop} {
    input {
      font-size: 0.8rem;
    }
  }
`;

const Menu = styled.div`
  cursor: pointer;
`;
