import { createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "sidebar",
  initialState: false,
  reducers: {
    setSideBar: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

const { actions, reducer: sidebarReducer } = userSlice;

const { setSideBar } = actions;

export { sidebarReducer, setSideBar };
