import { request } from "../Utils";

export const getCategories = (filter) =>
    request({
        url: `product-categories`,
        method: 'GET',
        params: {
            ...filter
        }
    })

export const createCategories = (data) =>
    request({
        url: `product-categories`,
        method: 'POST',
        data
    })


export const deleteCategories = (id) =>
    request({
        url: `product-categories/${id}`,
        method: 'DELETE',
    })