import { Skeleton } from "@mui/material";
import React from "react";
import { styled } from "styled-components";
import { devices } from "../../../../Utils";

export const ProductPageLoading = () => {
  return (
    <Container>
      <BreadCrumb variant="rectangular" width={400} height={25} />
      <ContentWrapper>
        <ImagesWrapper>
          <Skeleton variant="rectangular" width={`100%`} height={550} />
          <MoreImagesWrapper>
            <Skeleton variant="rectangular" width={`100%`} height={140} />
            <Skeleton variant="rectangular" width={`100%`} height={140} />
            <Skeleton variant="rectangular" width={`100%`} height={140} />
            <Skeleton variant="rectangular" width={`100%`} height={140} />
          </MoreImagesWrapper>
        </ImagesWrapper>
        <DetailsWrapper>
          <Seller variant="text" sx={{ fontSize: "16px" }} />
          <Skeleton variant="text" sx={{ fontSize: "82px" }} />
          <Skeleton variant="text" sx={{ fontSize: "16px" }} />
          <EntryWrapper>
            <Skeleton variant="text" sx={{ fontSize: "16px" }} />
            <Skeleton variant="rectangular" width={`100%`} height={175} />
          </EntryWrapper>
          <EntryWrapper>
            <Skeleton variant="text" sx={{ fontSize: "16px" }} />
            <Skeleton variant="rectangular" width={`100%`} height={45} />
          </EntryWrapper>
          <EntryWrapper>
            <Skeleton variant="text" sx={{ fontSize: "16px" }} />
            <Skeleton variant="rectangular" width={`100%`} height={45} />
          </EntryWrapper>
          <EntryWrapper>
            <Skeleton variant="text" sx={{ fontSize: "16px" }} />
            <Skeleton variant="rectangular" width={`100%`} height={45} />
          </EntryWrapper>
        </DetailsWrapper>
      </ContentWrapper>
    </Container>
  );
};


const BreadCrumb = styled(Skeleton)`
  @media ${devices.mobileL} {
    width: 100% !important;
    height: 43px !important;
  }
`;

const Seller = styled(Skeleton)`
  margin-bottom: 30px;
`;



export const MoreImagesWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 14px;
  overflow: hidden;
  margin-top: 25px;

  @media ${devices.mobileL} {
    gap: 8px;
    margin-top: 15px;
  }
`; 

export const ImagesWrapper = styled.div`
  width: 45%;

  @media ${devices.mobileL} {
    width: 100%;
  }
`;


export const EntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;

  @media ${devices.mobileL} {
    gap: 15px;
  }
`;


export const DetailsWrapper = styled.div`
  padding-top: 5px;
  width: 48%;

  @media ${devices.mobileL} {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 70px;
  margin-top: 30px;

  @media ${devices.mobileL} {
    flex-direction: column;
    gap: 20px;
  }
`;


export const Container = styled.div`
  padding: 35px 5% 0;

  @media ${devices.mobileL} {
    padding: 30px 20px;
  }
`;