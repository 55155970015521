import styled from "styled-components";
import { GButton, GModal, LineLoader } from "../../../../Ui_elements";
import {
  ErrorPaymentVector,
  PaymentError,
  WarningCircle,
} from "../../../../Assets/Svgs";
import { devices } from "../../../../Utils";

export const RejectItemModdal = ({
  openModal,
  setOpenModal,
  deleteAction,
  isLoading
}) => {
  return (
    <GModal open={openModal.rejectModal} handleClose={() => setOpenModal({
      ...openModal,
      rejectModal: false
    })}>
      <Container>
        <FailedHead>
          <Vector>
            <ErrorPaymentVector />
          </Vector>
          <Icon>
            <WarningCircle />
          </Icon>
        </FailedHead>

        <Scroller>
          <Detail>
            <h5>Are you sure you want to Reject this request?</h5>
            <p>
              If the user is rejected, they will not be able to access wholesale prices.
            </p>
          </Detail>

          <Footer>
            <GButton
              red
              onClick={() => {
                deleteAction()
                setOpenModal(false)
              }}
              width={"100%"}
              label={"Yes, Reject "}
            />
            <GButton
              onClick={() => setOpenModal(false)}
              width={"100%"}
              outline
              label={"No"}
            />
          </Footer>
        </Scroller>
      </Container>
      <LineLoader loading={isLoading} />
    </GModal>
  );
};

const Container = styled.div`
  width: 600px;
  height: 437px;
  background-color: white;
  padding-bottom: 40px;

  @media ${devices.tablet}{
    width: 90vw;
    height: 90vw;
  }
`;

const Scroller = styled.div`
  @media ${devices.tablet}{
    overflow-y: auto;
    max-height: 40vw;
  }
`
const Head = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-yellow);
`;

const FailedHead = styled(Head)`
  background-color: #fff6f7;
`;
const Vector = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 0;

  @media ${devices.tablet}{
    width: 60px;
    height: 60px;
    & > svg{
      width: 100px;
      height: 100px;
    }
  }
`;

const Icon = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: red;
  position: relative;
  z-index: 3;

  @media ${devices.tablet}{
    width: 60px;
    height: 60px;
    & > svg{
      width: 60px;
      height: 60px;
    }
  }
`;

const Detail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  margin: 2rem 0 46px 0;
  padding: 0 40px;
  h5,
  p {
    text-align: center;
  }
  h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }

  @media ${devices.tablet}{
    h5{
      font-size: 19px;

    }
    p{
      font-size: 0.8rem;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 20px;
  padding: 0 40px;
`;
