import { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { DashboardLayout } from "../Layouts";
import { PageLoader } from "../Ui_elements";
import { authRoutes, routes } from "./routes";
import { useSelector } from "react-redux";

export const AppRoutes = () => {
  const location = useLocation();
  const userData = useSelector((state) => state?.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {userData
        ? routes.map(({ path, element: Element, hasLayout }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<PageLoader />}>
                  {hasLayout ? (
                    <Element />
                  ) : (
                    <DashboardLayout>
                      <Element />
                    </DashboardLayout>
                  )}
                </Suspense>
              }
            />
          ))
        : authRoutes.map(({ path, element: Element, hasLayout }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<PageLoader />}>
                  {hasLayout ? (
                    <Element />
                  ) : (
                    <DashboardLayout>
                      <Element />
                    </DashboardLayout>
                  )}
                </Suspense>
              }
            />
          ))}
    </Routes>
  );
};
