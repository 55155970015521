import { request } from "../Utils";

export const getCurrencies = () =>
    request({
        url: `currencies/conversions`,
        method: 'GET',
    })


export const convertCurrency = (data) =>
    request({
        url: `currencies/add-conversion`,
        method: "POST",
        data,
    });