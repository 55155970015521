import { BuyerTable } from "../sections/buyers";
import { SellersTable } from "../sections/sellers";
import { WholesaleRequestTable } from "../sections/wholesaleRequest";

export const userManagementHash = {
  buyers: `#buyers`,
  stores: `#stores`,
  wholesale: `#wholesale_requests`,
};

export const userManagementTabData = [
  {
    label: `Buyers`,
    hashName: userManagementHash.buyers,
    Body: BuyerTable,
  },
  {
    label: `Stores`,
    hashName: userManagementHash.stores,
    Body: SellersTable,
  },
  {
    label: `Wholesale Requests`,
    hashName: userManagementHash.wholesale,
    Body: WholesaleRequestTable,
  },
];
