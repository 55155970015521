import { request } from "../Utils";

export const getAllStores = (filter) =>
  request({
    url: `admin/stores`,
    method: "GET",
    params: {
      ...filter,
    },
  });

export const getDashboardOrders = (filter) =>
  request({
    url: `admin/orders`,
    method: "GET",
    params: {
      ...filter,
    },
  });
