import React from "react";
import { styled } from "styled-components";
import authImage from "../../Assets/Images/auth_bg.png";
// import authGradient from "../../Assets/Images/sign_up_gradient.png";
import { Logo } from "../../Assets/Svgs";
import { devices } from "../../Utils/mediaQueryBreakPoints";

export const AuthLayout = ({ children }) => {
  return (
    <Container>
      <BgImage src={authImage} />
      <LeftSection>
        <AuthLogo />
      </LeftSection>
      <RightSection>
        <ContentWrapper>{children}</ContentWrapper>
      </RightSection>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;

  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  padding: 64px 80px;
  overflow: hidden;
  background: var(--Primary-50, #ffece9);

  @media ${devices.tablet} {
    width: 100%;
    padding: 20px;
    height: 40%;
  }
`;

const BgImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 42vw;
  height: 85vh;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 2;
`;

const AuthLogo = styled(Logo)`
  width: 167.805px;
  height: 32px;
  flex-shrink: 0;

  @media ${devices.tablet} {
    width: 165px;
    height: 28px;
  }
`;
const LeftTextWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0 80px 64px 80px;
  z-index: 1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);

  @media ${devices.tablet} {
    padding: 20px;
  }
`;

const LeftTitle = styled.h2`
  font-size: 3.6vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: #fefefe;

  @media ${devices.tablet} {
    font-size: 5vw !important;
    line-height: 1.2 !important;
  }

  @media ${devices.laptop} {
    font-size: 40px;
    line-height: 40px;
  }
`;

const LeftSubtitle = styled.p`
  font-family: Barlow;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #fefefe;
  @media ${devices.tablet} {
    font-size: 0.8rem;
    line-height: 1.2;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  padding-left: 252px;
  position: relative;
  width: 70%;
  background: var(--White, #fefefe);

  @media ${devices.tablet} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  width: 42vw;
  max-width: 740px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 0;
  background: var(--White, #fefefe);

  @media ${devices.tablet} {
    max-width: 100%;
    max-height: 60vh !important;
    padding: 20px;
  }
`;
