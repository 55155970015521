import { request } from "../Utils";


export const getWholesaleBuyers = (filter) =>
    request({
        url: `buyer/wholesale`,
        method: 'GET',
        params: {
            ...filter
        }
    })


export const respondToWholesaleBuyers = (data) =>
    request({
        url: `buyer/wholesale/approve-request`,
        method: 'POST',
        data
    })