import { request } from "../Utils";

const year = new Date().getFullYear();

export const getBuyers = (filter) =>
    request({
        url: `buyer`,
        method: 'GET',
        params: {
            ...filter
        }
    })

export const getSingleBuyer = (id) =>
    request({
        url: `buyer/${id}`,
        method: 'GET',
    })

export const getSingleBuyerOrder = (filter) =>
    request({
        url: `orders`,
        method: 'GET',
        params: {
            ...filter
        }
    })



export const getStores = (filter) =>
    request({
        url: `admin/stores`,
        method: 'GET',
        params: {
            ...filter
        }
    })

export const approveStores = (id) =>
    request({
        url: `/admin/stores/approve/${id}`,
        method: 'PUT',
    })

export const getSingleSeller = (id) =>
    request({
        url: `/stores/${id}`,
        method: 'GET',
    })

export const getSellerDashboardData = (storeId) =>
    request({
        url: `store/${storeId}/order/inventory?year=${year}`,
    });

export const getSellerStore = (id) =>
    request({
        url: `admin/stores/${id}`,
        method: 'GET',
    })


export const getSellerDocs = (id) =>
    request({
        url: `/store/kyc/${id}/docs`,
        method: 'GET',
    })


export const updateSeller = (data, id) =>
    request({
        url: `/admin/stores/${id}`,
        method: 'PUT',
        data
    })

    export const getStoreStats = (id) =>
        request({
            url: `/admin/stores/${id}/stats`,
            method: 'GET',
        })
    
